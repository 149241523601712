@import '~@entur/button/dist/styles.css';
@import '~@entur/expand/dist/styles.css';
@import '~@entur/icons/dist/styles.css';
@import '~@entur/layout/dist/styles.css';
@import '~@entur/menu/dist/styles.css';
@import '~@entur/typography/dist/styles.css';
@import '~@entur/grid/dist/styles.css';
@import '~@entur/table/dist/styles.css';
@import '~@entur/form/dist/styles.css';
@import '~@entur/icons/dist/styles.css';
@import '~@entur/tooltip/dist/styles.css';
@import '~@entur/dropdown/dist/styles.css';
@import '~@entur/a11y/dist/styles.css';
@import '~@entur/chip/dist/styles.css';
@import '~@entur/alert/dist/styles.css';
@import '~@entur/expand/dist/styles.css';
@import '~@entur/layout/dist/styles.css';
@import '~@entur/datepicker/dist/styles.css';
@import '~@entur/loader/dist/styles.css';
@import '~@entur/modal/dist/styles.css';
@import '~@entur/tokens/dist/styles.css';

* {
    margin: 0;
}

.App {
    margin: 4rem;
}

header {
    padding: 2rem;
}

.codeContainer {
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: scroll;
    /* background-color: rgb(248, 248, 248); */
}

.eds-breadcrumbs {
    padding: 0;
}

.right {
    float: right;
}

.eds-icon-button {
    display: inline;
}

.eds-icon-button--disabled__wrapper {
    display: inline;
}
